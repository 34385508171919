import { render, staticRenderFns } from "./EditTaskTypeModal.vue?vue&type=template&id=2ba4f73c&scoped=true&"
import script from "./EditTaskTypeModal.vue?vue&type=script&lang=js&"
export * from "./EditTaskTypeModal.vue?vue&type=script&lang=js&"
import style0 from "./EditTaskTypeModal.vue?vue&type=style&index=0&id=2ba4f73c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba4f73c",
  null
  
)

export default component.exports